<template>
  <component :is="props.tag" class="lg:mx-4 lg:rounded-[20px] xl:mx-5">
    <div
      :class="props.padding ? 'py-14 md:py-28 xl:py-32' : ''"
      class="gutters lg:mx-6 xl:mx-8 2xl:mx-10"
    >
      <slot />
    </div>
  </component>
</template>

<script lang="ts" setup>
interface BackgroundInsetWrapperProps {
  padding?: boolean
  tag?: string
}

const props = withDefaults(defineProps<BackgroundInsetWrapperProps>(), {
  padding: false,
  tag: 'div'
})
</script>

<style lang="postcss" scoped></style>
