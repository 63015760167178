<template>
  <div class="pointer-events-none select-none">
    <img :src="`/img/cloud-swirl-${props.type}.png`" class="h-full w-full" />
  </div>
</template>

<script lang="ts" setup>
interface CloudSwirlProps {
  type?: '1' | '2' | '3' | '4' | '5' | 1 | 2 | 3 | 4 | 5
}

const props = withDefaults(defineProps<CloudSwirlProps>(), { type: '1' })
</script>
